<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('users.disabled-users') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" md="3">
          <UserMenu />
        </b-col>
        <b-col cols="12" md="8" offset-sm="1">
        </b-col>
      </b-row>
</b-container>
</div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import UserMenu from "@/components/admin/user/UserMenu.vue";
export default {
  name: "DisabledUsers",
  components: { NavBar, UserMenu }
}
</script>
<style></style>
